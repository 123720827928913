import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { VorratAddComponent } from '../vorrat-add/vorrat-add.component';
import { ThrowStmt } from '@angular/compiler';
import { EventEmitter } from '@angular/core';

/**
 * @title Filter autocomplete
 */
@Component({
  selector: 'app-lebensmittel-suche',
  templateUrl: './lebensmittel-suche.component.html',
  styleUrls: ['./lebensmittel-suche.component.scss']
})
export class LebensmittelSucheComponent implements OnInit {

  searchLebensmittelCtrl = new FormControl();
  filteredItems: any;
  isLoading = false;
  errorMsg: string;

  @Input()
  savedValue: any;

  @Output()
  result$ = new EventEmitter<string>()

  result: string;

  constructor(
    private http: HttpClient
  ) { }

  onResult(value: string) {
    this.result = value;
    this.result$.next(value);
  }

  ngOnInit() {
    this.searchLebensmittelCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredItems = [];
          this.isLoading = true;
        }),
        switchMap(value => this.http.get("https://app.satoha.de/service/?m=s&t=" + value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe(data => {
        if (data['result'] == undefined) {
          //if (data == undefined) {
          this.errorMsg = data['Error'];
          this.filteredItems = [];
        } else {
          this.errorMsg = "";
          this.filteredItems = data['result'];
          //this.filteredItems = data;
        }
        console.log("Result:");
        console.log(this.filteredItems);
        //console.log(data['result'][0].name);
        if (data['result']) {
          this.result = data['result'][0].name;
          console.log("Gruppe: " + data['result'][0].gruppe_name)
        }

      });
  }
}