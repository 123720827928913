import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LebensmittelSucheComponent } from '../lebensmittel-suche/lebensmittel-suche.component';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { VorratAutocompleteGruppeComponent } from '../vorrat-autocomplete-gruppe/vorrat-autocomplete-gruppe.component';
import { GlobalsService } from '../common/globals.service'; 

@Component({
  selector: 'app-vorrat-add',
  templateUrl: './vorrat-add.component.html',
  styleUrls: ['./vorrat-add.component.scss']
})
export class VorratAddComponent {

  title = 'satoha-app';

  @ViewChild(LebensmittelSucheComponent)
  suche: LebensmittelSucheComponent;
  @ViewChild(VorratAutocompleteGruppeComponent)
  suchegruppe: VorratAutocompleteGruppeComponent;
  
  selectedGruppe: any;
  selectedAblaufdatum: Date;
  selectedMenge: String;
  selectedKategorie: String;
  selectedLagerort: String;
  selectedEinheit: String;

  lastInsertArticle: any;
  minExpDate = new Date();
  status: String;

  // get selectedKategorie(): any{
  //   return this._selectedKategorie;
  // }

  // set selectedKategorie(value: any){
  //   console.log('here setting value: ' + value)
  //   this._selectedKategorie = value;
  // }

  
  constructor(private http: HttpClient, public _global: GlobalsService) { 
    _global.values$.subscribe( globals => {
      this.selectedEinheit = globals.einheiten[0].id;
      this.selectedKategorie = globals.kategorien[0].id;
      this.selectedLagerort = globals.lagerorte[0].id;
    })
  }
  



  addItem(lebensmittel = this.suche.result, gruppe = this.suchegruppe.result, menge = this.selectedMenge, einheit = this.selectedEinheit, kategorie= this.selectedKategorie, ort = this.selectedLagerort, ablaufdatum = this.selectedAblaufdatum){
    //console.log(moment(ablaufdatum).format('YYYY-MM-DD'));

    // console.log("lebensmittel: "+lebensmittel);
    // console.log("GRUPPE: "+gruppe);
    // return;
    
    if (lebensmittel === undefined || lebensmittel == "" || ablaufdatum === undefined || ablaufdatum == null) {
      this.status = "Du hast das Formular nicht komplett ausgefüllt.";
      return;
    }
    console.log("lebensmittel hinzufügen an server: "+lebensmittel+" "+menge+" "+einheit+" "+kategorie+" "+ort+" "+ablaufdatum);
    
    this.http.post<any>('https://app.satoha.de/service/?m=add-item&name='+lebensmittel+"&gruppe="+gruppe+"&menge="+menge+"&einheit_id="+einheit+"&kategorie_id="+kategorie+"&ort_id="+ort+"&verfallsdatum="+moment(ablaufdatum).format('YYYY-MM-DD'), { title: 'Angular POST Request Example' }).subscribe(data => {
      if (data.success) {  
        this.lastInsertArticle = data;
        
        this.suche.result = "";
        this.suche.searchLebensmittelCtrl.reset('');
        this.suchegruppe.result = "";
        this.suchegruppe.searchGruppeCtrl.reset('');
        this.selectedMenge = "";
        this.selectedAblaufdatum = null;
      } else {
        this.status = "Es ist ein Fehler aufgetreten: "+data.message;
      }
    });
  }


}
