import { HttpClient } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { GlobalsService } from '../common/globals.service'; 
import { literalArr } from '@angular/compiler/src/output/output_ast';
import { isNgTemplate } from '@angular/compiler';
import * as moment from 'moment';
import { Router } from '@angular/router';

// export interface PeriodicElement {
//   id: number;
//   name: string;
//   ort: string;
//   verfallsdatum: any;
// }

// const ELEMENT_DATA: PeriodicElement[] = [
//   {id: 1, name: 'Hydrogen', ort: "ss", verfallsdatum: '2020-10-11'},
//   {id: 2, name: 'Helium', ort: "ss", verfallsdatum: 'He'}
// ];

@Component({
  selector: 'app-vorrat-list',
  templateUrl: './vorrat-list.component.html',
  styleUrls: ['./vorrat-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class VorratListComponent {
  displayedColumns: string[] = ['name', 'menge', 'lagerplatz', 'verfallsdatum'];
  //dataSource = ELEMENT_DATA;
  dataSource: any;
  expandedElement: null;
  //currentItem: Object = false;
  currentItem: {[k: string]: any} = {};   // damit man dynamisch noch elemente hinzufügen kann
  globalsGruppen: any;

  constructor(private http: HttpClient, private router: Router, public _global: GlobalsService) { 
    this.getListe(); 
    _global.values$.subscribe( globals => {
      this.globalsGruppen = globals.gruppen;
    })
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  applyFilterID(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = "#"+filterValue.trim().toLowerCase()+"#";
  }

  getListe(){
    console.log("Hole Vorratsliste vom Server...");
    this.http.post<any>('https://app.satoha.de/service/?m=get-vorrat-liste', { title: 'Angular POST Request Example' }).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
    });
  }

  getItem(dbid) {
    this.http.post<any>('https://app.satoha.de/service/?action=get-vorrat&type=id&value='+dbid, { title: 'Angular POST Request Example' }).subscribe(data => {
      // this.lastInsertArticle = data;
      
      // this.suche.result = "";
      // this.suche.searchLebensmittelCtrl.reset('');
      // this.selectedMenge = "";
      // this.selectedAblaufdatum = null;
      // console.log(data.name);
      console.log("getItem()");
      this.currentItem = data;
      console.log("gruppe id: "+data.gruppe_id);
      console.log(this.globalsGruppen);
      this.currentItem.dbid = dbid;     // speichere die dbid um diese später zu verwenden

      // TODO
      let found = this.globalsGruppen.find(e => e.id === data.gruppe_id);
      console.log(found);
      if (found) {
        console.log("gruppe name gefunden: "+found.name);
        this.currentItem.gruppe_name = found.name;
      }

    });
  }
  updateItem(lebensmittel = this.currentItem.name, gruppe = this.currentItem.gruppe_name, menge = this.currentItem.menge, einheit = this.currentItem.einheit_id, kategorie= this.currentItem.kategorie_id, ort = this.currentItem.lagerort_id, ablaufdatum = this.currentItem.verfallsdatum) {
    console.log("updateItem()");
    console.log("dbid: "+this.currentItem.dbid);
    console.log("objekt currentItem:");
    console.log(this.currentItem);
    if (this.currentItem.dbid > 1) {
      this.http.post<any>('https://app.satoha.de/service/?m=update-item&dbid='+this.currentItem.dbid+'&name='+lebensmittel+"&gruppe="+gruppe+"&menge="+menge+"&einheit_id="+einheit+"&kategorie_id="+kategorie+"&ort_id="+ort+"&verfallsdatum="+moment(ablaufdatum).format('YYYY-MM-DD'), { title: 'Angular POST Request Example' }).subscribe(data => {
        console.log(data);
        if (data.success) {  
          console.log("ok: "+data.message);

          this.router.navigate(['home']);
        } else {
          console.log("fehler: "+data.message);
          //this.status = "Es ist ein Fehler aufgetreten: "+data.message;
        }
      });
    }
  }
  
  deleteItem(item) {
    this.http.post<any>('https://app.satoha.de/service/?action=delete-vorrat&type=id&value='+item.dbid, { title: 'Angular POST Request Example' }).subscribe(data => {
      const index = this.dataSource.filteredData.indexOf(item);
      var dbid = this.dataSource.filteredData[index].dbid;

      this.dataSource.data = this.dataSource.data.filter(function( obj ) {    // aus dem objekt das gelöschte element rausschmeissen
        return obj.dbid !== dbid;
      });
      
      this.dataSource._updateChangeSubscription(); // <-- Refresh the datasource
    });


  }
}

