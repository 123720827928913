import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../common/globals.service'; 

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private _global: GlobalsService) { 
    this._global.getRuntimedata();    // Lade die Runtime-Daten, wenn der Homebildschirm geladen wird
  }

  status: string;
  
  ngOnInit(): void { 
  }

}