import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from '../common/globals.service'; 

@Component({
  selector: 'app-vorrat-search',
  templateUrl: './vorrat-search.component.html',
  styleUrls: ['./vorrat-search.component.scss']
})
export class VorratSearchComponent implements OnInit {

  constructor(private http: HttpClient, public _global: GlobalsService) { 
    _global.values$.subscribe( globals => {
      // this.selectedEinheit = globals.einheiten[0].id;
      // this.selectedKategorie = globals.kategorien[0].id;
      // this.selectedLagerort = globals.lagerorte[0].id;
    })
  }

  search_marker: number;
  status: string = "";

  // vorrat_name: string;
  // vorrat_menge: number;
  // vorrat_ablaufdatum: any;
  // vorrat_lagerort: 1;

  selectedName: String;
  selectedGruppe: any;
  selectedAblaufdatum: Date;
  selectedMenge: any;
  selectedKategorie: String;
  selectedLagerort: String;
  selectedEinheit: String;

  //marker: number;
  minExpDate = new Date();

  ngOnInit(): void {
  }

  searchItem(marker = this.search_marker) {
    console.log("seachItem()");
    this.http.post<any>('https://app.satoha.de/service/?action=get-vorrat&type=marker&value='+marker, { title: 'Angular POST Request Example' }).subscribe(data => {
      // this.lastInsertArticle = data;
      
      // this.suche.result = "";
      // this.suche.searchLebensmittelCtrl.reset('');
      // this.selectedMenge = "";
      // this.selectedAblaufdatum = null;
      if (data.success) {
        this.selectedMenge = data.menge;
        this.selectedName = data.name;
        this.selectedAblaufdatum = data.verfallsdatum;
        this.selectedKategorie = data.kategorie;
        this.selectedEinheit = data.einheit;
        this.selectedLagerort = data.lagerort
      } else {
        this.selectedName = "";
        this.status = data.message;
      }
      


      // console.log(data[0].menge);
    });
  }

  updateItem(marker = this.search_marker) {
    console.log("updateItem()");
    this.http.post<any>('https://app.satoha.de/service/?action=get-vorrat&type=marker&value='+marker, { title: 'Angular POST Request Example' }).subscribe(data => {
      // this.lastInsertArticle = data;
      
      // this.suche.result = "";
      // this.suche.searchLebensmittelCtrl.reset('');
      // this.selectedMenge = "";
      // this.selectedAblaufdatum = null;
    });
  }

  deleteItem(marker = this.search_marker) {
    console.log("Auslagere Produkt mit Marker: "+marker);
    this.http.post<any>('https://app.satoha.de/service/?action=delete-vorrat&type=marker&value='+marker, { title: 'Angular POST Request Example' }).subscribe(data => {
      console.log(data.success);
      console.log(data.message);
      if (data.success) {
        this.selectedName = "";
        this.status = data.message;
      } else {
        this.status = data.message;
      }
    });
  }
}
