<button mat-icon-button routerLink="/" color="primary" aria-label="Startseite">
    <mat-icon>home</mat-icon>
  </button>
  
  <!-- <mat-slider min="1" max="100" step="1" value="1"></mat-slider>
  <app-home></app-home> -->
  
  
  <p>Suche in Vorratsdatenbank:</p>
  <div class="example-form">
    <!-- <app-lebensmittel-suche></app-lebensmittel-suche>
  
    <app-vorrat-autocomplete-gruppe></app-vorrat-autocomplete-gruppe>
     -->
    <mat-form-field class="example-full-width">
      <mat-label>Markersuche</mat-label>
      <input matInput type="number" placeholder="Dreistelliger Marker" [(ngModel)]="search_marker">
    </mat-form-field>
    
    <button mat-button (click)="searchItem()">Suchen</button>

  </div>

  <div *ngIf="selectedName" class="example-form">
    <p>Ergebnis:</p>
    <!-- <app-lebensmittel-suche></app-lebensmittel-suche>
  
    <app-vorrat-autocomplete-gruppe></app-vorrat-autocomplete-gruppe>-->

    <mat-form-field class="example-full-width">
      <mat-label>Produkt</mat-label>
      <input matInput placeholder="In Gramm" [(ngModel)]="selectedName">
    </mat-form-field>
    <br />

    <mat-form-field class="example-full-width">
      <mat-label>Menge</mat-label>
      <input matInput type="number" placeholder="In Gramm" [(ngModel)]="selectedMenge">
    </mat-form-field>
    
    <mat-form-field *ngIf="_global.values$ | async as globals">
      <mat-label>Einheit</mat-label>
      <mat-select [(value)]="selectedEinheit">
        <mat-option *ngFor="let einheit of globals.einheiten;" [value]="einheit.id" >
          {{einheit.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
 
    <mat-form-field *ngIf="_global.values$ | async as globals">
      <mat-label>Kategorie</mat-label>
      <mat-select [(value)]="selectedKategorie">
        <mat-option *ngFor="let kategorie of globals.kategorien" [value]="kategorie.id">
          {{kategorie.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
   
    <mat-form-field *ngIf="_global.values$ | async as globals">
      <mat-label>Lagerort</mat-label>
      <mat-select [(value)]="selectedLagerort">
        <mat-option *ngFor="let ort of globals.lagerorte" [value]="ort.id">
          {{ort.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
  
    <mat-form-field>
      <mat-label>Ablaufdatum</mat-label>
      <input matInput [matDatepicker]="myDatePicker" [min]="minExpDate" [(ngModel)]="selectedAblaufdatum">
      <mat-datepicker-toggle [for]="myDatePicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #myDatePicker startView="multi-year"></mat-datepicker>
    </mat-form-field>
    <br />

    <button mat-button (click)="updateItem()">Updaten</button>
    <button mat-button (click)="deleteItem()">Löschen</button>
  </div>
  
  <div *ngIf="status" class="example-form">
    <p>{{status}}</p>
  </div>

  
  <!-- <div class="data">
    <ul>
      <li *ngFor="let item of itemArray">{{item}}</li>
    </ul>
  </div> -->
  