import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  constructor(private http: HttpClient) {  this.getRuntimedata(); }

  //global_kategorien: object; 

  values$ = new ReplaySubject<{
    einheiten: object,
    gruppen: object,
    kategorien: object,
    lagerorte: object
  }>();

  getRuntimedata(){
      console.log("Lade die Runtime-Daten...");
      this.http.post<any>('https://app.satoha.de/service/?m=get-vorrat-runtimedata', { title: 'Angular POST Request Example' }).subscribe(data => {
        // console.log(data.kategorien);
        // this.global_teststr = "Gesetzt";
        // this.global_kategorien = data.kategorien;
        this.values$.next({
          einheiten: data.einheiten,
          gruppen: data.gruppen,
          kategorien: data.kategorien,
          lagerorte: data.orte
        })
      });
      //setTimeout( () => this.values$.next({teststr: 'ersetzt', kategorien: null}), 3000);
    }
}
