<div>
 
  <mat-form-field>
    <input #lebensmittel matInput placeholder="Produkt" aria-label="State" [(ngModel)]="savedValue" [matAutocomplete]="auto" [formControl]="searchLebensmittelCtrl" (ngModelChange)='onResult($event)'>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngIf="isLoading" class="is-loading">Suche...</mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let item of filteredItems" [value]="item.name">
          <span>{{item.name}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <!-- <ng-template #elseTemplate>{{searchLebensmittelCtrl.value}}</ng-template> -->
 
 
<!-- <ng-container *ngIf="errorMsg; else elseTemplate">
  {{errorMsg}}
</ng-container> -->

 <!-- <mat-button class="md-primary">-Mat Button</mat-button> -->
</div>