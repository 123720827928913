

  <div class="example-form">
    <app-lebensmittel-suche [savedValue]="item.name" (result$)="item.name = $event"></app-lebensmittel-suche>
    
    <app-vorrat-autocomplete-gruppe [savedValue]="item.gruppe_name" (result$)="item.gruppe_name = $event"></app-vorrat-autocomplete-gruppe>

    <!-- <mat-form-field class="example-full-width">
      <mat-label>Produkt</mat-label>
      <input matInput placeholder="In Gramm" [(ngModel)]="item.name">
    </mat-form-field> -->
    <br />

    <mat-form-field class="example-full-width">
      <mat-label>Menge</mat-label>
      <input matInput type="number" placeholder="In Gramm" [(ngModel)]="item.menge">
    </mat-form-field>
    
    <mat-form-field *ngIf="_global.values$ | async as globals">
      <mat-label>Einheit</mat-label>
      <mat-select [(value)]="item.einheit_id">
        <mat-option *ngFor="let einheit of globals.einheiten;" [value]="einheit.id" >
          {{einheit.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
 
    <mat-form-field *ngIf="_global.values$ | async as globals">
      <mat-label>Kategorie</mat-label>
      <mat-select [(value)]="item.kategorie_id">
        <mat-option *ngFor="let kategorie of globals.kategorien" [value]="kategorie.id">
          {{kategorie.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
   
    <mat-form-field *ngIf="_global.values$ | async as globals">
      <mat-label>Lagerort</mat-label>
      <mat-select [(value)]="item.lagerort_id">
        <mat-option *ngFor="let ort of globals.lagerorte" [value]="ort.id">
          {{ort.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
  
    <mat-form-field>
      <mat-label>Ablaufdatum</mat-label>
      <input matInput [matDatepicker]="myDatePicker" [min]="minExpDate" [(ngModel)]="item.verfallsdatum">
      <mat-datepicker-toggle [for]="myDatePicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #myDatePicker startView="multi-year"></mat-datepicker>
    </mat-form-field>
    <br />
    
  </div>
  
  <div *ngIf="status" class="example-form">
    <p>{{status}}</p>
  </div>

  
  <!-- <div class="data">
    <ul>
      <li *ngFor="let item of itemArray">{{item}}</li>
    </ul>
  </div> -->
  